<template>
  <div class="box__container">
    <div class="box__title">Bankverbindung</div>

    <div v-if="isMainPerson || (backButton && backButton.path)">
      <BaseButton v-if="isEditable" @click="openModalEditBankAccount(true)" class="mb-3">Hinzufügen</BaseButton>

        <Table v-if="rows.length"
            tableId="46c30c0a-aa2b-4885-b3ef-cfedaeec59ce"
            :headers="headers"
            :rows="rows"
            :mobileConfig="{title: 'Bankverbindung', headers: ['bankName', 'owner', 'iban']}"
            :exportConfig="{roottext: 'Bankverbindung'}"
            @action-EDIT="openModalEditBankAccount(false,$event)"
            @action-DELETE="deleteBankverbindungConfirmation"
        />
        <div v-else>Keine Daten</div>

      <BaseModal
        @onConfirmButton="saveBankAccount()"
        ref="editBankAccount"
        modalTitle="Bankverbindung"
        :confirmDisabled="!isEditable || isFormValid || checking"
      >
        
        <InputField label="Inhaber Vorname"
          v-model="editAccount.ownerFirstName"
          validateUntouched
          :disabled="!isEditable"/>

        <InputField label="Inhaber Nachname"
          v-model="editAccount.ownerLastName"
          validateUntouched
          :disabled="!isEditable"/>

        <InputField label="IBAN"
          v-model="editAccount.iban"
          validateUntouched
          :disabled="!isEditable"
          @change="findIBANDetails()"
          @onBlur="checkIfIBANIsInvalid($event.target.value)"
          @input="checkLettersIban($event)"/>

        <InputField label="SWIFT-BIC"
          v-model="editAccount.bic"
          validateUntouched
          :disabled="!isEditable"
          @input="checkLetters($event)"/>

        <InputField label="Bank"
          v-model="editAccount.bankName"
          :disabled="!isEditable"/>

        <InputField label="Ort der Bank"
          v-model="editAccount.bankCity"
          :disabled="!isEditable"/>

        <ComboBox label="Bankverbindungstyp"
          :values="customerDataConfig.bankAccountTypes"
          :firstEmpty="!hasValidComboValue('editAccount', 'typeOfAccount', customerDataConfig.bankAccountTypes)"
          v-model="editAccount.typeOfAccount"
          :disabled="!isEditable"/>

        <InputToggleSwitch v-if="isAVL"
          label="Verwendung für DWS Erstattung der AVL"
          v-model="editAccount.isDWSErstattung4AVL"
        />

        <InputField label="Bemerkung"
          v-model="editAccount.annotation"
          :disabled="!isEditable"/>

        <InputToggleSwitch
          label="Als Standard setzen"
          v-model="editAccount.standard"
        />

      </BaseModal>

      <BaseModal
        labelButtonConfirm = "löschen"
        @onConfirmButton="deleteBankverbindung()"
        ref="deleteBankverbindungConfirmation"
        modalTitle="Bankverbindung löschen">
          {{ deleteMessage }}
      </BaseModal>

      <BaseModal ref="fehlerModal" labelButtonCancel="Ok" :showConfirmButton="false">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Fehler
      </template>
      <div class="var(--color-danger) font-bold">
        <hr>
         Diese Bankverbindung ist bereits im System vorhanden. Bitte bearbeiten Sie die vorhandene Bankverbindung
      </div>
    </BaseModal>
    </div>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { mapGetters } from 'vuex'
import validator from '@/mixins/validator'
import { required } from '@/mixins/validator/rules'
import {PhWarning } from 'phosphor-vue'
import InputField from '@/components/core/forms/InputField.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, IconColumn, Icon} from "@/components/table2/table_util.js";
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import { ROLES }from '@/router/roles';
import CORE_TYPES from '@/store/core/types';

export default {
  mixins: [validator],
  data() {
    return { 
        editAccount: {},
        deleteAccount: {},
        invalidIBAN: {},
        checking: false,
        isNewBankAccount: false,
        headers: {
            lockedLeft: [
                TextColumn("bankName", "Bank"),
                TextColumn("owner", "Inhaber"),
            ],
            center: [
                TextColumn("iban", "IBAN"),
                TextColumn("bic", "SWIFT-BIC"),
                TextColumn("bankCity", "Ort der Bank"),
                TextColumn("typeOfAccount", "Bankverbindungstyp"),
                TextColumn("annotation", "Bemerkung"),
                IconColumn("standardIconColumn", "Standard").makeAlwaysVisible(),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    }
  },
  watch: {
    'editAccount.ownerFirstName': function() {

      if (!this.isInsuranceCompany && !this.isFirma) {
        const valConfig = {};
        valConfig['editAccount.ownerFirstName'] = [required('Inhaber Vorname ist erforderlich!')];
        this.$configureValidators(valConfig);         
      }
   
    },
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      backButton: CUSTOMERDATA_TYPES.GETTERS.GET_BACK_BUTTON,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
      isAVL: CORE_TYPES.GETTERS.IS_AVL,
    }),
    isEditable() {
      return this.customerData?.isEditable;
    },
    bankAccounts() {
        return this.customerData?.bankAccounts || []
    },
    rows() {
        const actions = [
            SimpleAction("EDIT", this.isEditable ? 'PhPencilLine' : 'PhEye', "Bearbeiten"),
        ];
        if (this.isEditable)
            actions.push(SimpleAction("DELETE", 'PhTrash', "Löschen"));
        return this.bankAccounts?.map((ba) => ({
            bankName: ba.bankName,
            owner: `${ba.ownerFirstName || ''} ${ba.ownerLastName}`,
            iban: ba.iban,
            bic: ba.bic,
            bankCity: ba.bankCity,
            typeOfAccount: ba.typeOfAccount,
            annotation: ba.annotation,
            ownerFirstName: ba.ownerFirstName,
            ownerLastName: ba.ownerLastName,
            id: ba.id,
            standardIconColumn: ba.standard ? [Icon('PhCheck', "Ja", 20)] : [],
            standard: ba.standard,
            actions: ba.isEditable ? actions : [],
            bereich: ba.bereich,
            isDWSErstattung4AVL: ba.bereich == 'DWS',
        }))
    },
    deleteMessage() {
      return `Soll die Bankverbindung ${this.deleteAccount.bankName} (${this.deleteAccount.iban}) wirklich gelöscht werden?`
    },
    isFormValid() {
      return this.validation?.updated && this.validation?.isInvalid('editAccount');
    },
    isInsuranceCompany() {
      return this.hasRoles([ROLES.INSURANCE_COMPANY])
    },
  },
  validators: {
    editAccount: {
      ownerLastName: [required('Inhaber Nachname ist erforderlich!')],
      iban: [required('IBAN ist erforderlich!')],
      bic: [required('BIC ist erforderlich!')],
    }
  },
  methods: {
    emptyBankAccount() {
      return {
        id: 'new_account',
        ownerFirstName: this.customerData?.personalDataAddress?.firstName,
        ownerLastName: this.customerData?.personalDataAddress?.lastName,
        iban: '',
        bic: '',
        bankName: '',
        bankCity: '',
        typeOfAccount: '',
        annotation: '',
        standard: this.rows?.length ? false : true,
        isDWSErstattung4AVL: this.isAVL ? false : null,
      }
    },
    openModalEditBankAccount(isNew,account) {
      this.isNewBankAccount = isNew;
      this.editAccount = {};
      this.$refs.editBankAccount.open();
      this.$nextTick(() => this.editAccount = this.properName(account && { ...account, actions: null } || this.emptyBankAccount()));
    },
    properName(editAccount) { // MSC-29130 (warum?)
      function change(name) {
        const words = name.split(' ').map(w => {
          if (' VON DER ZU ZUR UND DE VAN '.includes(' '+w+' ')) {
            return w.toLowerCase();
          } else {
            return w.charAt(0) + w.slice(1).toLocaleLowerCase();
          }
        });
        return words.join(' ');
      }
      if (editAccount.ownerFirstName && editAccount.ownerFirstName == editAccount.ownerFirstName.toUpperCase()) {
        editAccount.ownerFirstName = change(editAccount.ownerFirstName);
      }
      if (editAccount.ownerLastName && editAccount.ownerLastName == editAccount.ownerLastName.toUpperCase()) {
        editAccount.ownerLastName = change(editAccount.ownerLastName);
      }
      return editAccount;
    },
    saveBankAccount() {
      if (!this.customerData || !this.customerData.personId ) {
        return
      }

      const bankingAccount = this.editAccount

      if(this.isNewBankAccount && this.bankAccounts.filter(ba => (ba.iban == bankingAccount.iban
      && ba.ownerLastName == bankingAccount.ownerLastName && ba.ownerFirstName == bankingAccount.ownerFirstName)).length > 0){
        this.$refs.fehlerModal.open();
        return;
      }

      if (this.isAVL) {
        if (bankingAccount.bereich === 'DWS' && !bankingAccount.isDWSErstattung4AVL) {
          bankingAccount.bereich = ''; // remove DWS
        } else if (bankingAccount.bereich != 'DWS' && bankingAccount.isDWSErstattung4AVL) {
          bankingAccount.bereich = '+DWS'; // set to DWS
        }
      }
      delete bankingAccount.isDWSErstattung4AVL;
      const payload = {
        personId: this.personId,
        bankingAccount
      }

      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload)
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA).then(()=>{
        if (bankingAccount?.id !== 'new_account') {
           this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload) //needed for the Änderungsformular prompt
        }
      })
    },
    deleteBankverbindungConfirmation(row) {
      this.deleteAccount = row;
      this.$refs.deleteBankverbindungConfirmation.open()
    },

    deleteBankverbindung() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_BANKVERBINDUNG, { bankverbindungId: this.deleteAccount.id })
    },

    async findIBANDetails() {
      const { iban, bic } = this.editAccount;

      if(!iban) return ;
      // || iban in this.invalidIBAN

      try {
        this.checking = true;
        const ibanDetails = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_IBAN_DETAILS, { iban, });
        if(ibanDetails?.error) {
          this.$set(this, 'invalidIBAN', {
            ...this.invalidIBAN,
            [iban]: ibanDetails.error,
          });
        }

        if(!bic) {
          this.editAccount.bic = this.editAccount.bic || ibanDetails?.bic;
          this.editAccount.bankName = this.editAccount.bankName || ibanDetails?.bankName;
          this.editAccount.bankCity = this.editAccount.bankCity || ibanDetails?.bankCity;
          
          let payload = [];
          
          if (!this.editAccount?.bic) {
            payload.push('BIC ist erforderlich!');
          }

          payload.push(ibanDetails?.autoCalcText);

          this.$pushErrors('editAccount.bic', payload)

        }
      } finally {
        this.checkIfIBANIsInvalid(iban);
      
        this.checking = false;
      }
    },
    checkIfIBANIsInvalid(iban) {
      const error = this.invalidIBAN?.[iban];
      if(error) {
        this.$pushErrors('editAccount.iban', error);
      }
        this.editAccount.ibanChanged = true;
    },
    checkLetters(c){
      if(c.toLowerCase() != c.toUpperCase()){
        this.editAccount.bic = c.toUpperCase()
      }
      const regex = new RegExp('\s', 'g')
     if(this.editAccount.bic.includes(' ')){
      this.editAccount.bic = this.editAccount.bic.replace(' ','')
     }
     if(c.includes(' ')){
      this.editAccount.bic = this.editAccount.bic.replaceAll(' ','')
     }
    },
    checkLettersIban(c){
      if(c.toLowerCase() != c.toUpperCase()){
        this.editAccount.iban = c.toUpperCase()
      }
      const regex = new RegExp('\s', 'g')
     if(this.editAccount.iban.includes(' ')){
      this.editAccount.iban = this.editAccount.iban.replace(' ','')
     }
     if(c.includes(' ')){
      this.editAccount.iban = this.editAccount.iban.replaceAll(' ','')
     }
    },
    hasValidComboValue(group, field, possibleValues = []) {
      const currentValue = this?.[group]?.[field];
      if(!currentValue) return false;
      return possibleValues?.findIndex(item => item === currentValue || item?.value === currentValue) >= 0;
    },
  },
  components: {
    BaseButton,
    BaseModal,
    InputField,
    Table,
    ComboBox,
    InputToggleSwitch,
    PhWarning
  }
}
</script>

<style scoped>

</style>
<template>
<div>
	<PageHeaderTitleNavigation 
		:defaultMenu="$appNavigation.currentTabMenu" 
		:title="formatName + ' Datenimport'"
		subtitle="Daten einlesen" 
		:actions="headerActions" 
		@action-IMPORT-FILE="$refs.importFileModal.open()"
		@action-STATUS-WERTE="$refs.statusWerteModal.open()"
		@action-AUSWERTEN="actionAuswertung(firstRowObject)"
	/>

	<div v-if="!showFullFilter" class="box__container">
		<span class="box__title">
			Anleitung
		</span>
		<br>
		<div>
			Mit der Funktion Datenimport haben Sie die Möglichkeit, <b>Bestandsdaten</b> im	<b>{{formatName}}-Format</b> einzulesen und zu aktualisieren. 
			Als Formate können {{formatName}}, aber auch z.B. Textdateien verwendet werden. 
			Kundendaten werden automatisch angelegt. 
			Somit halten Sie Ihre Versicherungsdaten auf dem aktuellen Stand.
			<b>Bitte beachten Sie unbedingt die Anleitung und lesen Sie diese sorgfältig durch.</b>
			<br><br>
			Folgendes bitten wir Sie zu beachten:
			<br><br>
			Die Nutzung dieser Funktion ist kostenpflichtig und mit der Nutzung erkennen Sie dies an. 
			Zurzeit liegen die Gebühren bei 0,10€ pro angelegtem Vertrag/ Monat zzgl. der gesetzlichen MwSt. 
			Die ersten drei Monate können Sie das Tool kostenfrei nutzen, um sich über die Funktionalitäten einen Überblick zu schaffen. 
			Wir übernehmen keinerlei Haftung für die Qualität der Daten. 
			Falls Sie Fehler entdecken sollten, bitten wir Sie uns eine Nachricht einzustellen, damit wir uns so schnell wie Möglich darum kümmern können.
			<br><br>
			<DownloadLink title="Anleitung" :href="anleitungLink" />
		</div>
	</div>

	<BaseFilter
		ref="gdvFileImportViewFilter"
		title="Daten einlesen suchen"
		filterId="Daten einlesen suchen"
		:configFilter="filterConfig"
		:metadata="metadata"
		:defaultOptions="defaultOptions"
		:immidiateSearch="!!$route.query.filename"
		showSaveButton
		@onFilter="onFilter"
		@onFilterZurucksetzen="onResetFilter"
	>
		<span v-if="filterErrorMessage" class="color-danger">
			{{filterErrorMessage}}
		</span>
	</BaseFilter>

	<div class="gdv-file-import-view__content" :class="{ 'is-details-panel-visible': isDetailsPanelVisible, }">
		<div class="gdv-file-import-view__table box__container">
			<div v-if="loading || !rows.length">
				<span class="box__title">
					Daten Einlesen
				</span>
				<br>
			</div>
			<GhostLoading v-if="loading" type="table"/>
			<Table v-else-if="rows.length" style="clear: both;"
				ref="mainTable"
				tableId="92137f1f-e07b-4081-88ad-ab5521691fb0"
				title="Daten Einlesen"
				:headers="headers"
				:rows="rows"
				:rowsPerPage="rowsPerPage"
				ignoreTablePageSize
				:tableRowsPerPage="tableRowsPerPage"
				@onPageSizeChange="onPageSizeChange"
				@click-id="showLineDetails"
				@action-EDIT="lineEdit=$event"
				@action-AUSWERTUNG="actionAuswertung"
				@action-DATENSATZ_ZUORDNEN="actionDatensatzZuordnen"
				@action-TARIF="actionTarif"
				@action-KUNDENDATEN_ZUWEISEN="actionKundendatenZuweisen"
				@action-DELETE="deleteRow"
			>
		
				<template v-slot:datensatzZuordnen="row">  
					<PhArrowBendUpRight class="clickable" v-if="row.actionDatensatz" @click="actionDatensatzZuordnen(row)" :size="24"/>
				</template>

			</Table>
			<NoData v-else/>
		</div>
		<div class="details__panel" v-if="isDetailsPanelVisible">
			<div class="box__container">
				<GhostLoading v-if="readDetails" type="table"/>	
				<Table
					v-else-if="!readDetails && journalRows" style="clear: both;"
					:headers="journalHeaders"
					:rows="journalRows"
					rowId="index"
					hidePagination
				/>		
				<NoData v-else/>
				
			</div>
			<div class="box__container">
				<GhostLoading v-if="readDetails" type="table"/>
				<Table
					v-else-if="!readDetails && lineDetails.values" style="clear: both;"
					:headers="valuesHeaders"
					:rows="lineDetails.values"
					rowId="label"
					hidePagination
				/>
				<NoData v-else/>
				
			</div>
		</div>
	</div>


	<ImportFileModal ref="importFileModal"/>
	<FileImportLineEdit v-if="lineEdit" :line="lineEdit" @close="lineEdit = null"/>
	<GdvImportAuswertungModal v-if="auswertungId" :id="auswertungId" @close="closeAuswertung"/>
	
	<BaseModal ref="infoModal"
	  :showCancelButton="false" 
	  labelButtonConfirm="Schließen"
	>
	  <template v-slot:modalTitle>Daten Einlesen</template>
	  <template #default>
      <div v-html="sanitize(message)"></div>
	  </template>
	</BaseModal>

	<BaseModal ref="statusWerteModal"
	  :showCancelButton="false" 
	  labelButtonConfirm="Schließen"
	>
	  <template v-slot:modalTitle>Status Werte</template>
	  <template #default>
		<Table
			style="clear: both;"
			:headers="statusWerteHeaders"
			:rows="statusWerteRows"
			rowId="wert"
			hidePagination
				/>		
	  </template>
	</BaseModal>

</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import VERSICHERUNGSTARIFE_TYPES from "@/store/versicherungstarife/types";

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import FileImportLineEdit from '@/components/bipro/FileImportLineEdit.vue'
import ImportFileModal from '@/components/bipro/ImportFileModal.vue'
import GdvImportAuswertungModal from '@/components/bipro/GdvImportAuswertungModal.vue'
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import {sanitize} from '@/helpers/string-helper.js';

import {TextColumn, DateColumn, DateTimeColumn, NumberColumn, ActionColumn, SimpleAction, ConfirmedAction, LoadingAction, DownloadLinkAction, SlotColumn} from "@/components/table2/table_util.js";
import axios from 'axios';
import { mapGetters } from 'vuex';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import {PhArrowSquareOut, PhFloppyDiskBack, PhPencilLine, PhArrowFatRight, PhTrash} from 'phosphor-vue';
import {BROKER_LEVEL_ROLES, ROLES} from '@/router/roles';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { PhArrowBendUpRight} from "phosphor-vue";
import GDV_TYPES from '@/store/gdv/types'

const config = {
	defaultSpinner: true,
};

const LINE_DETAILS_TABLE_CELL_PROPS = {
	lineClamp: 999,
  maxWidth: '100%',
};

export default {
	mixins: [],
	components: {
		PageHeaderTitleNavigation,
		OptionMenu,
		BaseFilter,
		Table,
		GhostLoading,
		NoData,
		FileImportLineEdit,
		ImportFileModal,
		GdvImportAuswertungModal,
		DownloadLink,
		BaseModal,
		PhArrowBendUpRight,
	},
	data() {
		return {
			loading: false,
			readDetails: false,
			filterConfig: {
				placeholderForDefSearchInput: 'Dateiname',
				checkDefaultSearchInput: false,
				cbDefSearchInput: null,
				hideFirstColumn: true,
				filterZurucksetzen: null,
				noResetOnDefaultSearchInputExit: true,
			},
			filterParams: {},
			filterErrorMessage: null,
			lines: null,
			lineDetails: null,
			lineEdit: null,
			rowToDelete: null,
			auswertungId: null,
			message: '',
			currentRow: null,
			rowsPerPage: 100,
			tableRowsPerPage: [
				{value: 5, label: '05 Zeilen pro Seite'}, 
				{value: 10, label: '10 Zeilen pro Seite'}, 
				{value: 25, label: '25 Zeilen pro Seite'}, 
				{value: 50, label: '50 Zeilen pro Seite'}, 
				{value: 100, label: '100 Zeilen pro Seite'}
      		],			
		};
	},
	computed: {
		...mapGetters({
			isFK: CORE_TYPES.GETTERS.IS_FK,
			isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
			isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
			hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
			savedFilters: GDV_TYPES.GETTERS.SAVED_FILTERS,
		}),
		statusWerteHeaders() {
			const headers = { 
				lockedLeft: [
					TextColumn("wert", "Wert"),
					TextColumn("beschreibung", "Beschreibung"),
				],
			};
			return headers;
		},			
		statusWerteRows() {
			const rows = [
				{ wert: '-1', beschreibung: 'Wert fehlt/falsch bei Stammdaten' },
				{ wert: '-2', beschreibung: 'Wert fehlt/falsch Folgefehler' },
				{ wert: '-3', beschreibung: 'Fehler in Datenbank z.B. Wert mehrfach vorhanden' },
				{ wert: '-4', beschreibung: 'Daten in Datei oder Datenbank falsch zugeordnet. Zum Beispiel Maklernummer.' },
				{ wert: '-5', beschreibung: 'Erforderliche Zusatzangabe fehlt/falsch' },
				{ wert: '-6', beschreibung: 'Angabe zu Sparte bzw. Portfolio fehlt/falsch' },
				{ wert: '-7', beschreibung: 'Formatfehler z.B. bei Policenummer, Objektnummer oder ISIN' },
				{ wert: '-8', beschreibung: 'Daten in Datei oder Datenbank falsch zugeordnet. Zum Beispiel Vertragsnummer.' },
				{ wert: '-9', beschreibung: 'Allgemeiner Fehler. ERROR-MESSAGE aus IF wird angezeigt.' },
				{ wert: '-10', beschreibung: 'Fremdbezeichnung nicht in Datenbank' },
				{ wert: '-13', beschreibung: 'Vertrag nicht in Datenbank' },
				{ wert: '-14', beschreibung: 'Allgemeiner Fehler. Personendaten können nicht gespeichert werden.' },
				{ wert: '-15', beschreibung: 'Allgemeiner Fehler. Kunden/Kontodaten können nicht gespeichert werden.' },
				{ wert: '-16', beschreibung: 'Zu viele Personen mit gleichem Namen, Gebdatum, PLZ gefunden.' },
				{ wert: '-19', beschreibung: 'Fremdwiederanlage Antragsnr_2 fehlt in Datei.' },
				{ wert: '-20', beschreibung: 'Allgemeiner Fehler. Fremdwiederanlage.' },
				{ wert: '-21', beschreibung: 'Auskommentiert' },
				{ wert: '-22', beschreibung: 'CAPITALBANK Fehler: Pondon_Storno finden ohne BGS Fehler.' },
				{ wert: '1/0', beschreibung: 'Abrechnungen Ok.' },
				{ wert: '2/0', beschreibung: 'Versicherungen Ok.' }
			];


			return rows;
		},
		isDetailsPanelVisible() {
			return !this.loading && (this.readDetails || this.journalRows?.length);
		},
        journalRows() {
            return (this.lineDetails?.journal || []).map((row, index) => ({
                index,
                ...row,
            }));
        },	
		journalHeaders() {
			const headers = { 
				lockedLeft: [
					DateTimeColumn("label", "Zeit"),
					TextColumn("value", "Journal-Text").addCellProps(LINE_DETAILS_TABLE_CELL_PROPS),
				],
			};
			return headers;
		},	
		valuesHeaders() {
			const headers = { 
                lockedLeft: [
                    TextColumn("label", "Beschreibung"),
                    TextColumn("value", "Inhalt").addCellProps(LINE_DETAILS_TABLE_CELL_PROPS),
                ],
			};
			return headers;
		},		
		headerActions() {
			return [
				PageHeaderSimpleAction('IMPORT-FILE', 'Datei einlesen'),
				PageHeaderSimpleAction('STATUS-WERTE', 'Status Werte'),
				PageHeaderSimpleAction('AUSWERTEN', 'Auswertung').withDisabled(() => !this.firstRowObject),
			];
		},
		anleitungLink() {
			return `${process.env.VUE_APP_API}/download_service/bipro_gdv_anleitung`;
		},
		showFullFilter() { // == bFilterVisible in MSC
			return (this.isIntern || this.hasRoles(BROKER_LEVEL_ROLES.RIGHT_DATENIMPORT));
		},
		showingErrorFile() { 
			return this.$route?.query?.filename && this.$route?.query?.showFile;
		},		
		formatName() {
			return this.isFK ? "GDV" : "OMDS";
		},
		metadata() {
			const result = [
				{
					type: 'group',
					key: 'allgemein',
					label: 'Allgemein',
					menuItems: [
						{
							type: 'text',
							label: 'Dateiname *',
							key: 'filename',
							emptyDenied: true,
						},
					],
				},
			];
			if (this.showFullFilter) {
				result[0].menuItems.push(
					{
						type: 'numberRange',
						label: 'Zeilennummer',
						key: 'lineRange',
						emptyDenied: true,
					},
					{
						type: 'dateRange',
						label: 'Zeitraum',
						key: 'zeitraum',
						emptyDenied: true,
					},
					{
						type: 'text',
						label: 'Gesellschaft *',
						key: 'gesellschaft',
						emptyDenied: true,
					},
					{
						type: 'text',
						label: 'Interface *',
						key: 'interfacename',
						emptyDenied: true,
					},
					{
						type: 'text',
						label: 'Subinterface *',
						key: 'subinterfacename',
						emptyDenied: true,
					},
					{
						type: 'combobox',
						label: 'Auswahl',
						key: 'use4Booking',
						comboOptions: [
							{label: "aktive Sätze", value: 1},
							{label: "zurückgestellte Sätze", value: 0},
							{label: "nicht berücksicht", value: -1},
						],
					},
					{
						type: 'combobox',
						label: 'ab Status',
						key: 'statusFrom',
						comboOptions: [
							{label: "Fehler (<0)", value: -1000},
							{label: "gelesen (0)", value: 0},
							{label: "ausgewertet (1)", value: 1},
							{label: "übertragen (2)", value: 2},
							{label: "abgeschlossen (3)", value: 3},
						],
					},
					{
						type: 'combobox',
						label: 'bis Status',
						key: 'statusTo',
						comboOptions: [
							{label: "Fehler (<0)", value: -1},
							{label: "gelesen (0)", value: 0},
							{label: "ausgewertet (1)", value: 1},
							{label: "übertragen (2)", value: 2},
							{label: "abgeschlossen (3)", value: 3},
							{label: "alle (>=3)", value: 500},
						],
					},
					{
						type: 'text',
						label: 'Journal-Text',
						key: 'journal',
						emptyDenied: true,
					},
				);
			} else {
				result[0].menuItems.push(
					{
						type: 'default',
						label: 'nur fehlerhafte Datensätze anzeigen',
						key: 'onlyErrors',
						emptyDenied: true,
					},
				);
			}
			return result;
		},
		filters() {
            return this.savedFilters || {};
        }, 
		defaultOptions() {
            if (this.filters?.filters) {
				let defaultFilters = {};
                Object.keys(this.filters?.filters)?.forEach(key => defaultFilters[key] = { value: this.filters?.filters[key]});
				return defaultFilters;
            }

			if (this.showingErrorFile) {
				return {
					filename: {value: this.$route.query.filename || ""},
					onlyErrors: {value: false},
					use4Booking: {value: 1,},					
				};
			}

			if (!this.showFullFilter) {
				return {
					filename: {value: this.$route.query.filename || ""},
					onlyErrors: {value: true},
				};
			}
			const startDate = new Date();
			startDate.setMonth(startDate.getMonth() - 1);
			const result = {
				use4Booking: {
					value: 1,
				},
				statusFrom: {
					value: -1000,
				},
				statusTo: {
					value: 1,
				},
			};
			if (this.$route.query.filename)
				result.filename = {value: this.$route.query.filename};
			return result;
		},
		headers() {
			const headers = {
				lockedLeft: [
					NumberColumn("index", "lfd.Nr."),
					SlotColumn("datensatzZuordnen", "Datensatz zuordnen"),
					NumberColumn("id", "Zeilennr").makeLink(),
					TextColumn("fileName", "Dateiname", 300).addCellProps({lineClamp: 4}),
				],
				center: [
					TextColumn("interfaceName", "Interfacename").addCellProps({lineClamp: 4}),
					NumberColumn("status", "Status"),
					DateColumn("date", "Datum"),
				],
				lockedRight: [
					ActionColumn("actions", "Aktionen"),
				],
			};
			if (this.showFullFilter) {
				headers.center.unshift(TextColumn("gesellschaft", "Gesellschaft").addCellProps({lineClamp: 4}));
			}
			return headers;
		},
		rows() {
			return (this.lines || []).map((row, index) => {
				const actions = [
					SimpleAction("EDIT", PhPencilLine, "Datensatz bearbeiten"),
				];
				if (row.dateiAbfrage) {
					actions.push(ConfirmedAction("AUSWERTUNG", PhArrowSquareOut, "Auswertung", "Wollen Sie die folgende Auswertung durchführen?\n\n" + row.dateiAbfrage));
					if (this.showFullFilter)
						actions.push(DownloadLinkAction("SAVE", PhFloppyDiskBack, "Fehlerhafte Daten speichern", row.fileName, "/bipro_gdv_line_errors", {id: row.fileId}));
				}
				if (row.actionDatensatz)
					actions.push(SimpleAction("DATENSATZ_ZUORDNEN", PhArrowFatRight, "Datensatz zuordnen"));
				if (row.actionTarif) {
					if (row.actionTarif.disabled)
						actions.push(LoadingAction("", PhArrowFatRight, "Für den Spartentyp '" + row.actionTarif.versSpartentyp + "' kann kein Tarif angelegt werden", _ => true));
					else
						actions.push(SimpleAction("TARIF", PhArrowFatRight, "Für den Spartentyp '" + row.actionTarif.versSpartentyp + "' Tarif angelegen"));
				}
				if (row.actionKundendaten)
					actions.push(SimpleAction("KUNDENDATEN_ZUWEISEN", PhArrowFatRight, "Kundendaten zuweisen"));
				if (row.status <= 1)
					actions.push(ConfirmedAction("DELETE", PhTrash, "Löschen", "Wollen Sie den ausgewählten Datensatz wirklich löschen?"));
				return {
					index: index + 1,
					...row,
					customRowStyle: {
                      ...this.calculatedRowBackgroundColor(row, index), 
                    },
					actions,
				};
			});
		},
		firstRowObject() {
			return this.rows.length > 0 ? this.rows[0] : null;
		},
	},
	mounted() {
    	document.addEventListener('keydown', this.handleKeyDown);
  	},
	beforeDestroy() {
    	document.removeEventListener('keydown', this.handleKeyDown);
  	},
	methods: {
        onResetFilter() {
            this.$store.commit(GDV_TYPES.MUTATIONS.SAVE_FILTERS, this.metadata);
        },		
		calculatedRowBackgroundColor(whatRow, index) {
			if (index % 2== 0 && !whatRow.clicked) {
				return ( { backgroundColor: '#e3e3e3', } );
			}
			return (whatRow.clicked ? { fontWeight: 'bold', backgroundColor: '#e3e3e3', } : {});
		},
		onPageSizeChange(newPageSize) {
			this.rowsPerPage = newPageSize;
		},
		sanitize(htmlString) {
			return sanitize(htmlString);
		},
		async onFilter(filterConfig) {
			this.lineDetails = null;
			this.filterParams = {};

			filterConfig.forEach(fc => {
				let value = fc.value;
				if (fc.key == 'showDeleted' || fc.key == 'showFilesOfStructure')
					value = true; // the filters with above keys have no input field and should only be considered as boolean (true if added to the filter)
				if (fc.key == "onlyErrors") {
					this.filterParams.statusFrom = -1000;
					this.filterParams.statusTo = -1;
				} else if (fc.key == "zeitraum") {
					this.filterParams.fromDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
					this.filterParams.toDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
				} else if (fc.key == "lineRange") {
					this.filterParams.lineFrom = value.find(v => v.key == 'min').value;
					this.filterParams.lineTo = value.find(v => v.key == 'max').value;
				} else if (!fc.key.startsWith("lineRange")) {
					this.filterParams[fc.key] = value;
				}
			});
			this.filterParams.modus = 1;
			this.loadRows();
		},
		buildFilterErrorMessage() {
			this.filterErrorMessage = null;
			if (this.hasRoles(ROLES.IS_EXTERNAL_BROKER)) {
				if (!this.filterParams.filename)
					this.filterErrorMessage =  "Sie können nur suchen, wenn Sie zuvor eine Datei eingelesen haben.";
			} else {
				if (!this.showFullFilter && !this.filterParams.filename ||
					!this.filterParams.filename && !this.filterParams.lineFrom && !this.filterParams.lineTo && !this.filterParams.fromDate && !this.filterParams.toDate && !this.filterParams.gesellschaft && !this.filterParams.interface && !this.filterParams.subinterface)
					this.filterErrorMessage =  "Bitte befüllen Sie ein Suchfeld.";
			}
		},
		async loadRows() {
			this.buildFilterErrorMessage();
			if (this.filterErrorMessage || this.loading)
				return;

			// collapse base filter
			this.$refs.gdvFileImportViewFilter?.setCollapsed?.(false);

			this.loading = true;
			
			const config = { defaultSpinner: true, cancelableRequest: {title: 'Daten einlesen'} };
			const response = await axios.post(`${process.env.VUE_APP_API}/bipro_import/filter`, this.filterParams, config);
			if (response.data?.rows) {
				this.lines = response.data.rows;
				if (this.lines?.length) {
					this.showLineDetails(this.lines[0]);
				}
			} else {
				this.lines = null;
			}
			if (response.data?.mess) {
				this.message = response.data.mess;
				this.$refs.infoModal.open();
			}
			this.loading = false;
		},
		async showLineDetails(row) {
			if (this.readDetails) {
				return;
			}

			this.readDetails = true;
			const config = { defaultSpinner: true, cancelableRequest: {title: 'Details einlesen'} };
			const response = await axios.get(`${process.env.VUE_APP_API}/bipro_import/line_details?id=${row.id}`, config)
			this.lineDetails = {
				title: row.fileName + ", Zeile " + row.id,
				...response.data
			};
			this.readDetails = false;
			this.highlightRow(row);
			this.currentRow = row;

			this.scrollLineDetailsIntoView();
		},
		scrollLineDetailsIntoView() {
			const importViewTableEl = document.querySelector('.gdv-file-import-view__table');
			const detailsPanelEl = document.querySelector('.details__panel');

			this.$scrollIntoView(detailsPanelEl, {
				offsetY: (importViewTableEl?.clientHeight || 0) + 40,
			});
		},
		actionAuswertung(row) {
			this.auswertungId = row.fileId;
		},
		closeAuswertung(success) {
			this.auswertungId = null;
			if (success)
				this.loadRows();
		},
		actionDatensatzZuordnen(row) {
			this.$router.push({path: "/intern/gdv/import/zuordnen", query: {id: row.id}});
		},
		async actionTarif(row) {
			let response = await axios.get(`${process.env.VUE_APP_API}/bipro_import/gesellschaften`, config);
			this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN, response.data);
			response = await this.$store.dispatch(VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_SPARTE, {gesellschaftId: row.actionTarif.gesellschaftId});
			
			this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_TARIFE_DOKUMENTE);
			this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF, {
				gesellschaft: {
					id: row.actionTarif.gesellschaftId,
					disabled: true,
				},
				sparte: {
					id: row.actionTarif.versSpartentyp,
					valueList: response.values,
				},
			});
			this.$router.push({path: "/beratung/versicherungstarife/tarifBearbeiten"});
		},
		actionKundendatenZuweisen(row) {
			this.$router.push({path: "/intern/gdv/import/zuordnen", query: {id: row.id}});
		},
		deleteRow(row) {
			axios.delete(`${process.env.VUE_APP_API}/bipro_import/line?id=${row.id}`, config)
			.then(_ => {
				this.loadRows();
			})
		},
		highlightRow(whatRow) {
			let newArray = [];

			this.lines.forEach(obj => {
  				obj.clicked = false;
				  newArray.push(obj);
			});
			
			const rowToChange = newArray.find(l => l.id === whatRow.id);
			if (rowToChange) {
				rowToChange.clicked =true;
			}

			this.lines.length = 0;
			this.lines.push(...newArray);
		},
		handleKeyDown(event) {
			if (event.key === 'ArrowUp' && this.currentRow) {
				this.handleCursorUp(event);
			} else if (event.key === 'ArrowDown' && this.currentRow) {
				this.handleCursorDown(event);
			}
		},
		handleCursorUp(event) {
			event.preventDefault();
			if (this.currentRow?.index > 1) {
				const index = this.currentRow?.index -1;

				let isMultipleOfRowsPerPage = index % this.rowsPerPage;

				if (isMultipleOfRowsPerPage == 0 && this.$refs.mainTable.pageIndex > 0) {
					this.$refs.mainTable.pageIndex = this.$refs.mainTable.pageIndex -1;
				}				

				const row = this.rows.find(r => r.index ==index)
				this.showLineDetails(row);			
			}
			event.stopPropagation()
		},
		handleCursorDown(event) {
			event.preventDefault();

			let isMultipleOfRowsPerPage = this.currentRow?.index % this.rowsPerPage;

			const index = this.currentRow?.index +1;
			const row = this.rows.find(r => r.index ==index)
	
			if (row) {
				this.showLineDetails(row);
			}

			if (isMultipleOfRowsPerPage == 0 && this.$refs.mainTable.pageIndex < this.$refs.mainTable.pageCount) {
				this.$refs.mainTable.pageIndex = this.$refs.mainTable.pageIndex +1;
			}

			event.stopPropagation()
		},		
				
	},
	beforeRouteLeave(to, from, next) {
		this.$addBreadcrumb({ to, from, });
		next();
	},
}
</script>
<style scoped>
.gdv-file-import-view__content {
	display: flex;
	flex-flow: column;
}

.gdv-file-import-view__content.is-details-panel-visible {
	min-height: calc(100vh - 16px); /** view height - details panel margin-bottom */
}

.gdv-file-import-view__table {
	flex: 0 0 auto;
	width: 100%;
}

.details__panel {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 auto;
	min-height: 410px;
}
.details__panel > div {
	flex-direction: row;
	width: calc(49%);
}

.details__panel > div:first-child {
	margin-right: 16px;
}


@media screen and (max-width: 1290px) {
	.details__panel > div  {
		width: 100%;
	}

	.details__panel > div:first-child {
		margin-right: 0px;
	}	
}
</style>
